import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/home/hero";
import Why from "../components/home/why";
import Performance from "../components/home/performance";
import ContentManagement from "../components/home/content-management";
import CallToAction from "../components/home/call-to-action";

import { graphql } from "gatsby";

function IndexPage() {

  const lang = "en";
  
  return (
    <Layout lang={lang}>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <div>
        <Hero lang={lang} />
        <Why lang={lang} />
        <Performance lang={lang} />
        <ContentManagement lang={lang} />
        <CallToAction lang={lang} />
      </div>

    </Layout>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    contents: allContentfulHomepageContent {
      edges {
        node {
          headline
          subHeadline
          headlineDescription {
            headlineDescription
          }
          buttonText
        }
      }
    }
  }
`;